.landingPhoto {
    margin-top: 8px;
    max-width: 100%;
}

/* CatanaSupport */

.element {
    width: 100%;
    padding: 25px;
    margin-bottom: 30px;
}

.carousel-element {
    text-align: center;
}

.STL {
    height: 330px;
}

@media (min-width: 600px) {
    .STL {
        height: 400px;
    }
}

@media (min-width: 1200px) {
    .STL {
        height: 500px;
    }
}